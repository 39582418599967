import request from '../utils/request'

/**
 * 查询代理资源列表
 * @param params
 */
export const queryAgentList = (params) => {
  return request('POST', '/playerAgent/list', params, true, false)
}


/**
 * 查询资源详情
 * @param params
 */
export const queryAgentInfoById = (params) => {
  return request('POST', '/playerBase/detail', params, true, false)
}

/**
 * 新增资源
 * @param params
 */
export const addAgent = (params) => {
  return request('POST', '/playerAgent/add', params, true, false)
}


/**
 * 修改资源
 * @param params
 */
export const editAgent = (params) => {
  return request('POST', '/playerAgent/update', params, true, false)
}

/**
 * 修改代理资源相册
 * @param params
 */
export const editAgentPhotos = (params) => {
  return request('POST', '/playerAgent/updatePhotos',params, true, false)
}

/**
 * 更新背景图片
 * @param params
 */
export const updateAgentBackgroundImage = (params) => {
  return request('POST', '/playerAgent/updateBackgroundPhoto', params, true, false)
}

