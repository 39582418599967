<template>
  <div class="res-page">
    <Header title="MASK" :bgColor="'#000'" :subTitle="$t('agent.manageResource')"></Header>
    <div class="res-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" loading-text="111 " loosing-text="刷新"
        pulling-text="333 ">
        <van-list v-model="loading" :immediate-check="false" :finished="finished" :finished-text="$t('common.noMore')"
          @load="onReachBottom">
          <div class="res-item" v-for="(item, index) in agentList " :key="index" @click="editRes(item._id)">
            <div class="res-name">{{ item.nickname }}</div>
            <div class="res-photo">
              <img :src="$gbUtils.formatImgUrl(item.headPhoto)" />
            </div>
            <div class="res-btn">
              <img src="@/assets/img/icon/arrow.svg" />
            </div>
          </div>
          <div class="opt-btn" @click="addRes">
            <img src="@/assets/img/icon/add-gift.svg" />
            {{ $t('agent.addResource') }}
          </div>
        </van-list>
      </van-pull-refresh>

    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import { queryAgentList } from '@/service/agent'
export default {
  components: { Header },
  data() {
    return {
      refreshing: false, // 下拉刷新状态
      loading: false, // 加载状态
      finished: false, // 禁止加载
      agentList: [],
      pageSize: 100,
      pageNum: 1,
      total: 0
    }
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.pageNum = 1
      this.total = 0
      this.agentList = []
      this.finished = false
      this.getAgentList()
      this.refreshing = false
    },
    async getAgentList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const resp = await queryAgentList(params)
      this.loading = false
      if (!resp.success) return this.finished = true
      this.total = resp.data.total
      this.agentList.push(...resp.data.rows)
      if (this.pageNum * this.pageSize >= this.total) this.finished = true
    },
    async onReachBottom() {
      console.log('onReachBottom ....')
      if (this.pageNum * this.pageSize < this.total) {
        this.pageNum++
        await this.getAgentList()
      }
      this.loading = false
    },
    addRes() {
      this.$router.push({ path: '/agentAdd' })
    },
    editRes(userId) {
      this.$router.push({ path: '/agentSet', query: { userId: userId } })
    }
  },
  mounted() {
    this.getAgentList()
  }

}
</script>

<style lang="scss" scoped>
.res-page {
  font-size: 12px;
  background: #000;
  //height: calc(100vh - 96px);

  .res-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    background: #14151e;
    border-radius: 10px 10px 0 0;
    min-height: 500px;
    max-height: 500px;
    overflow-y: scroll;
    margin-bottom: 200px;

    .van-pull-refresh {
      width: 100%;
    }

    .res-item {
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      position: relative;
      background: #282A3B;
      margin-bottom: 10px;
      border-radius: 4px;

      .res-name {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-right: 30px;
        font-size: 13px;
        height: 100%;
        align-items: center;
      }

      .res-photo {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding: 10px;

        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
      }

      .res-btn {
        position: absolute;
        top: 19px;
        right: 10px;
        width: 12px;
        height: 12px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .opt-btn {
      width: 100%;
      height: 50px;
      background: #2C3341;
      font-size: 13px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 3px;
      }
    }


  }
}
</style>
